import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import ImagesGalleryListItem from "../components/ListItems/ImagesGalleryListItem"

const ImagesGalleriesPage = () => {
  const data = useStaticQuery(graphql`
    {
      imagesGalleries: allNodeAlbumPhoto(
        filter: { status: { eq: true } }
        sort: { order: DESC, fields: [created] }
      ) {
        nodes {
          id
          title
          body {
            value
          }
          images: field_images {
            alt
            width
            height
          }
          path {
            alias
          }
          relationships {
            images: field_images {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 332
                    maxHeight: 208
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageHeader title="Retour en images" subtitle="Vivre à Coucy" />
      <Metas
        title="Retour en images"
        description="Les albums photos de Coucy"
      />
      <section className="section-bottom page-content">
        <div className="container mx-auto px-4">
          <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mt-12">
            {data.imagesGalleries.nodes.map((node, index) => (
              <div key={index} className="column image-gallery">
                <ImagesGalleryListItem content={node} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ImagesGalleriesPage
