import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Small16by10 from "../Images/Small16by10"

const ImagesGalleryListItem = ({ content }) => (
  <div className="images-gallery-list-item relative">
    <div className="list-item-image">
      <Link to={content.path.alias} className="block overflow-hidden">
        {content.relationships.images[0] ? (
          <Img
            fluid={
              content.relationships.images[0].localFile.childImageSharp.fluid
            }
            alt={content.images[0].alt ? content.images[0].alt : content.title}
            className="transform hover:scale-110 transition-all duration-500"
          />
        ) : (
          <Small16by10 alt={content.title} />
        )}
      </Link>
    </div>
    <div className="list-item-content py-4 px-2 bg-white">
      <div className="text-lg font-semibold">
        <Link to={content.path.alias} className="text-secondary">
          {content.title}
        </Link>
      </div>
    </div>
  </div>
)

ImagesGalleryListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ImagesGalleryListItem
